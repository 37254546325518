import React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Head"
import { Section, Container, H2Title, M, P } from "../globalStyle"
const NotFoundPage = () => {
  return (
    <Layout>
      <Head
        description="Not found"
        pageTitle="Ooops, something went wrong :("
        keywords="software, oakfusion"
      />
      <Section style={{ marginTop: "80px" }}>
        <Container>
          <M>
            <H2Title>404: Not Found</H2Title>
            <P>You just hit a route that doesn&#39;t exist... the sadness.</P>
          </M>
        </Container>
      </Section>
    </Layout>
  )
}

export default NotFoundPage
